<script setup lang="ts">
import ChevronRight from '@/assets/svg/chevron-right-medium.svg?component'
import ChevronLeft from '@/assets/svg/chevron-left-medium.svg?component'

const props = defineProps({
  direction: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },
})
</script>

<template>
  <button class="arrow-button" :class="`arrow-button--${props.direction}`">
    <ChevronLeft v-if="props.direction === 'left'" />
    <ChevronRight v-else />
  </button>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';

.arrow-button {
  .button-reset();
  background-color: var(--green-1);
  .fluid(height, 28, 32);
  .fluid(width, 28, 32);
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--right {
    //
  }

  &:hover {
    .supports-hover({
      background-color: var(--green-2);
    });
  }

  svg {
    .fluid(height, 12, 14);

    path {
      stroke: var(--grey-1);
    }
  }
}
</style>
